
import { computed, defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmFormLine,
    TmButton,
    TmModal,
  },
  props: {
    edit: {
      type: Boolean,
    },
  },
  setup(props) {
    const { openModal } = useModals()
    const modalTitle = computed(() => props.edit ? 'Edit sub-account' : 'Create new sub-account')
    const name = ref(props.edit ? 'Adela Test`s sub-account' : '')
    const description = ref(props.edit ? 'This account is for the local shop management and communications in California' : '')
    const users = ref('nextStep')

    const handleClick = () => {
      if (users.value === 'nextStep' && !props.edit) {
        openModal('workspaceUsersSubAccount')
      }
    }

    return {
      handleClick,
      users,
      name,
      description,
      modalTitle,
    }
  },
})
