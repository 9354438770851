
import { defineComponent, ref, computed } from 'vue'
import Highlight from '@/components/shared/Highlight.vue'

export default defineComponent({
  components: {
    Highlight,
  },
  props: {
    label: {
      type: String,
    },
    text: {
      type: String,
    },
    hint: {
      type: String,
    },
    leftLabel: {
      type: Boolean,
      default: false,
    },
    leftLabelWidth: {
      type: String,
      default: '100px',
    },
    labelAlignRight: {
      type: Boolean,
    },
    required: {
      type: Boolean,
      default: false,
    },
    inputWrapperClass: {
      type: String,
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const inputWrapRef = ref<HTMLElement | null>(null)
    const hasTextSlot = !!context.slots.text
    const hasLabelSlot = !!context.slots.label
    const onLabelClick = () => {
      if (inputWrapRef.value) {
        const input = inputWrapRef.value.querySelector('input') || inputWrapRef.value.querySelector('textarea')
        // field with input
        if (input) {
          input.focus()
          input.click()
          return
        }
        // selects
        const select = inputWrapRef.value.querySelector('.q-field__control') as HTMLElement
        if (select) select.click()
      }
    }

    const labelWidth = computed(() => {
      return props.leftLabel ? props.leftLabelWidth : 'auto'
    })

    const requiredComputed = computed(() => {
      if (inputWrapRef.value) {
        return inputWrapRef.value.querySelector('[validators*=required]') !== null
      }
      return false
    })

    return {
      onLabelClick,
      inputWrapRef,
      labelWidth,
      hasTextSlot,
      hasLabelSlot,
      requiredComputed,
    }
  },
})
