
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { IconSize } from '@/definitions/shared/types'

export default defineComponent({
  components: { TmButton },
  props: {
    iconSize: {
      type: String as PropType<IconSize>,
      default: 'xLarge',
    },
  },
})
