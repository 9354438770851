import type { ComputedRef } from 'vue'
import { computed, ref } from 'vue'

export enum Modes {
  default,
  empty,
  emptyHalf,
  error,
  loading,
}
export type ModeNames = keyof typeof Modes

export const modesSettings: Record<ModeNames, { label: string; labelShort: string; }> = {
  default: {
    label: 'Standard',
    labelShort: 'Standard',
  },
  empty: {
    label: 'Empty state',
    labelShort: 'Empty',
  },
  emptyHalf: {
    label: 'Empty-half state',
    labelShort: 'Empty-half',
  },
  error: {
    label: 'Error state',
    labelShort: 'Error',
  },
  loading: {
    label: 'Loading state',
    labelShort: 'Loading',
  },
}

const LSName = 'TM_MODE'
const getInitialMode = (): ModeNames => window.localStorage.getItem(LSName) as ModeNames || 'default'
const setLSMode = (value: ModeNames) => window.localStorage.setItem(LSName, value)

type UseModesVal = {
  getMode: () => ComputedRef<ModeNames>,
  setMode: (value: ModeNames) => void,
  isEmptyMode: ComputedRef<boolean>,
  isErrorMode: ComputedRef<boolean>,
  isEmptyHalfMode: ComputedRef<boolean>,
  isLoadingMode: ComputedRef<boolean>,
}

const currentMode = ref<ModeNames>(getInitialMode())

export const useModes = (): UseModesVal => {
  const getMode = () => computed(() => currentMode.value)
  const setMode = (value: ModeNames) => {
    currentMode.value = value
    setLSMode(value)
  }

  const isEmptyHalfMode = computed(() => currentMode.value === 'emptyHalf')
  const isEmptyMode = computed(() => currentMode.value === 'empty')
  const isLoadingMode = computed(() => currentMode.value === 'loading')
  const isErrorMode = computed(() => currentMode.value === 'error')

  return {
    getMode,
    setMode,
    isLoadingMode,
    isEmptyMode,
    isEmptyHalfMode,
    isErrorMode,
  }
}
