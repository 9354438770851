import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79e2aac4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]
const _hoisted_2 = {
  key: 1,
  class: "tm-form-line__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_highlight = _resolveComponent("highlight")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-form-line", {
      'tm-form-line--left-label': _ctx.leftLabel,
      'tm-form-line--no-label': !_ctx.label && !_ctx.hasLabelSlot
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["tm-form-line__label-with-hint", {
        'text-right': _ctx.labelAlignRight
      }]),
      style: _normalizeStyle({
        'width' : _ctx.labelWidth,
        'min-width' : _ctx.labelWidth,
      })
    }, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createElementVNode("h6", {
          class: _normalizeClass({ 'required' : !_ctx.leftLabel && (_ctx.required || _ctx.requiredComputed) }),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLabelClick && _ctx.onLabelClick(...args)))
        }, [
          _createVNode(_component_highlight, {
            text: _ctx.label,
            search: _ctx.search
          }, null, 8, ["text", "search"])
        ], 2),
        (!_ctx.leftLabel)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              textContent: _toDisplayString(_ctx.hint)
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true)
      ], true)
    ], 6),
    (!_ctx.text && !_ctx.hasTextSlot)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref: "inputWrapRef",
          class: _normalizeClass(["tm-form-line__input-wrapper", _ctx.inputWrapperClass])
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "text", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.text), 1)
          ], true)
        ]))
  ], 2))
}