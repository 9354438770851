import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c47a916"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-modal-wrapper" }
const _hoisted_2 = { class: "flex-grow-1 lh-20" }
const _hoisted_3 = { class: "tm-modal__title" }
const _hoisted_4 = {
  key: 0,
  class: "tm-modal__title__icon"
}
const _hoisted_5 = { class: "tm-modal__header-right" }
const _hoisted_6 = {
  key: 2,
  class: "tm-modal__tabs"
}
const _hoisted_7 = {
  key: 3,
  class: "tm-modal__scroll"
}
const _hoisted_8 = {
  key: 0,
  class: "tm-modal__create-another"
}
const _hoisted_9 = { class: "tm-modal__footer-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_modal_close_btn = _resolveComponent("tm-modal-close-btn")!
  const _component_tm_error_state = _resolveComponent("tm-error-state")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ openModal: _ctx.openModal, hideModal: _ctx.hideModal })), undefined, true),
    _createVNode(_component_q_dialog, _mergeProps({ "model-value": _ctx.isOpen }, _ctx.computedAttrs, {
      persistent: !_ctx.onOverlayClose,
      class: _ctx.modalClasses,
      "transition-show": "jump-up",
      "transition-hide": "jump-down",
      "transition-duration": _ctx.transitionDuration,
      "onUpdate:modelValue": _ctx.onUpdate
    }), {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["tm-modal", {
          'tm-modal--with-aside': _ctx.leftAsideWidth,
        }]),
          style: _normalizeStyle(_ctx.modalStyles)
        }, [
          (_ctx.leftAsideWidth || _ctx.$slots.aside)
            ? _renderSlot(_ctx.$slots, "aside", { key: 0 }, undefined, true)
            : _createCommentVNode("", true),
          (!_ctx.noHeader)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["tm-modal__header", {
            'tm-modal__header--hide-shadow': _ctx.scrollAtStart,
          }])
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    (_ctx.titleIcon)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_tm_icon, {
                            name: _ctx.titleIcon,
                            class: _normalizeClass(_ctx.titleIconClass),
                            size: "xxxLarge"
                          }, null, 8, ["name", "class"])
                        ]))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(_ctx.modalTitle), 1)
                  ]),
                  _renderSlot(_ctx.$slots, "modal-subtitle", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "modal-top-right", {}, undefined, true),
                  _createVNode(_component_tm_modal_close_btn, { class: "mx-n2 my-n1" })
                ])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.hasTabs)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "modal-tabs", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isErrorMode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.noScroll ? 'div' : 'tm-scrollbar'), {
                  ref: "scrollbar",
                  class: _normalizeClass({'overflow-hidden d-flex': _ctx.noScroll}),
                  onOnYReachStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollAtStart = true)),
                  onOnYReachEnd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollAtEnd = true)),
                  onOnScroll: _ctx.resetScrollClasses
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["tm-modal__main", [{
                'tm-modal__main--no-header': _ctx.noHeader,
                'tm-modal__main--no-footer': _ctx.noFooter,
                'tm-modal__main--without-content-padding': _ctx.withoutContentPadding,
                'tm-modal__main--without-scroll': _ctx.scrollAtEnd && _ctx.scrollAtStart,
              }, _ctx.contentClass]])
                    }, [
                      _renderSlot(_ctx.$slots, "modal-content", _normalizeProps(_guardReactiveProps({ hideModal: _ctx.hideModal })), undefined, true)
                    ], 2)
                  ]),
                  _: 3
                }, 40, ["class", "onOnScroll"]))
              ]))
            : (_openBlock(), _createBlock(_component_tm_error_state, {
                key: 4,
                size: "small",
                class: "pb-16"
              })),
          _withDirectives(_createElementVNode("div", {
            class: _normalizeClass(["tm-modal__footer", {
            'tm-modal__footer--hide-shadow': _ctx.scrollAtEnd,
          }])
          }, [
            (_ctx.showCreateAnother)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_tm_field, {
                    modelValue: _ctx.createAnother,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.createAnother) = $event)),
                    type: "checkbox"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.createAnotherText), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
              _renderSlot(_ctx.$slots, "cancel-button", { hideModal: _ctx.hideModal }, () => [
                (!_ctx.hideCancelButton)
                  ? (_openBlock(), _createBlock(_component_tm_button, {
                      key: 0,
                      size: "large",
                      onClick: _ctx.hideModal
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.cancelButtonText), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ], true),
              _renderSlot(_ctx.$slots, "modal-footer-button", _normalizeProps(_guardReactiveProps({ hideModal: _ctx.hideModal })), undefined, true)
            ])
          ], 2), [
            [_vShow, !_ctx.noFooter && !_ctx.isErrorMode]
          ])
        ], 6)
      ]),
      _: 3
    }, 16, ["model-value", "persistent", "class", "transition-duration", "onUpdate:modelValue"])
  ]))
}