
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import _ from 'lodash'
import type { SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  name: 'ErrorState',
  components: { TmButton },
  props: {
    fullHeight: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<'small' | 'medium'>,
      default: '',
    },
  },
  setup() {
    return {
      kebabCase: _.kebabCase,
    }
  },
})
