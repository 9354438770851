import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return _withDirectives((_openBlock(), _createBlock(_component_tm_button, {
    "icon-only": "",
    flat: "",
    color: "transparent",
    class: "tm-modal-close-btn"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_icon, {
        name: "close",
        size: _ctx.iconSize
      }, null, 8, ["size"])
    ]),
    _: 1
  })), [
    [_directive_close_popup]
  ])
}