
import type { PropType } from 'vue'
import { computed, defineComponent, onMounted, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import TmErrorState from '@/components/shared/states/TmErrorState.vue'
import { useModes } from '@/compositions/modes'
import type { SizeProp } from '@/definitions/shared/types'
import { useModals } from '@/compositions/modals'
import type { ModalNames, ModalSize } from '@/components/modals/types'
import TmModalCloseBtn from '@/components/shared/modal/TmModalCloseBtn.vue'

export default defineComponent({
  components: { TmModalCloseBtn, TmErrorState, TmScrollbar, TmButton },
  props: {
    id: {
      type: String as PropType<ModalNames>,
    },
    modalTitle: {
      type: String,
    },
    size: {
      type: String as SizeProp<ModalSize>,
      default: 'medium',
    },
    exactSize: {
      type: String,
    },
    noFooter: {
      type: Boolean,
    },
    noHeader: {
      type: Boolean,
    },
    hideCancelButton: {
      type: Boolean,
    },
    onOverlayClose: {
      type: Boolean,
      default: true,
    },
    leftAsideWidth: {
      type: String,
      default: '',
    },
    showCreateAnother: {
      type: Boolean,
    },
    createAnotherText: {
      type: String,
      default: 'Add another after saving',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    withoutContentPadding: {
      type: Boolean,
    },
    titleIcon: {
      type: String,
    },
    titleIconClass: {
      type: String,
    },
    modalClasses: {
      type: String,
    },
    transitionDuration: {
      type: Number,
      default: 300,
    },
    maxHeight: {
      type: String,
    },
    minHeight: {
      type: String,
    },
    contentClass: {
      type: String,
      default: '',
    },
    noScroll: {
      type: Boolean,
    },
    customStyles: {
      type: Object,
    },
  },
  emits: ['show', 'hide'],
  setup(props, context) {
    const { getModalsNames, closeModal } = useModals()
    onMounted(() => {
      if (props.id && getModalsNames().value.includes(props.id)) {
        openModal()
      }
    })

    const scrollbar = ref()
    const scrollAtStart = ref(false)
    const scrollAtEnd = ref(false)

    const resetScrollClasses = () => {
      scrollAtStart.value = false
      scrollAtEnd.value = false
    }

    const createAnother = ref(false)

    const sizesMap: Record<ModalSize, string> = {
      small: '500px',
      medium: '600px',
      large: '700px',
      xLarge: '900px',
      full: '90%',
    }

    const modalSize = computed(() => {
      if (props.exactSize) return props.exactSize
      return sizesMap[props.size]
    })

    const modalStyles = computed(() => ({
      width: modalSize.value,
      maxHeight: props.maxHeight,
      minHeight: props.minHeight,
      height: props.maxHeight ? '100%' : '',
      ...(!!props.leftAsideWidth && { paddingLeft: props.leftAsideWidth }),
      ...props.customStyles,
    }))

    const isOpen = ref(false)
    const hideModal = async () => {
      isOpen.value = false
      setTimeout(() => {
        if (props.id) {
          closeModal(props.id)
        }
      }, props.transitionDuration)
      context.emit('hide')
    }
    const openModal = () => {
      isOpen.value = true
      context.emit('show')
    }
    const onUpdate = (val: boolean) => {
      val ? openModal() : hideModal()
    }

    const hasTabs = computed(() => {
      return context.slots['modal-tabs']
    })

    const { isErrorMode } = useModes()

    const computedAttrs = computed(() => {
      const { class: className, ...attrs } = context.attrs
      return attrs
    })

    return {
      scrollbar,
      scrollAtEnd,
      resetScrollClasses,
      createAnother,
      isOpen,
      scrollAtStart,
      hideModal,
      openModal,
      onUpdate,
      hasTabs,
      modalStyles,
      isErrorMode,
      computedAttrs,
    }
  },
})
